@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Sacramento&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f172a;
  scroll-behavior: smooth;
}



::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: #131830;
}

::-webkit-scrollbar-thumb {
  opacity: 0.5;
  background: rgb(30, 41, 59);
  border-radius: 1ex;
  -webkit-border-radius: 1ex;
}

.cell-grid {
  --grid-size: 3;
  --cell-size: 12vmax;
  --cell-gap: 0.5vmax;
  display: grid;
  grid-template-columns: repeat(var(--grid-size), var(--cell-size));
  grid-template-rows: repeat(var(--grid-size), var(--cell-size));
  gap: var(--cell-gap);
  padding: var(--cell-gap);
  position: relative;
  z-index: 10;
}

@media (min-width: 950px) {
  .cell-grid {
    --cell-size: 22vmin;
    grid-template-columns: repeat(var(--grid-size), var(--cell-size));
    grid-template-rows: repeat(var(--grid-size), var(--cell-size));
  }
}

@media (min-width: 750px) {
  .cell-grid {
    --cell-size: 24vmin;
    grid-template-columns: repeat(var(--grid-size), var(--cell-size));
    grid-template-rows: repeat(var(--grid-size), var(--cell-size));
  }
}

.cell {
  background-color: transparent;
  border-radius: 0.5rem;
}

.tile {
  --x: 2;
  --y: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--cell-size);
  height: var(--cell-size);
  background-color: transparent;
  top: calc(var(--y) * (var(--cell-size) + var(--cell-gap)) + var(--cell-gap));
  left: calc(var(--x) * (var(--cell-size) + var(--cell-gap)) + var(--cell-gap));
  transition: 100ms ease-in-out;
  background-color: #d2deee;
  border-radius: 0.5rem;
  z-index: 1;
}

#tile1 {
  --x: 0;
  --y: 0;
}
#tile2 {
  --x: 1;
  --y: 0;
}
#tile3 {
  --x: 2;
  --y: 0;
}
#tile4 {
  --x: 0;
  --y: 1;
}
#tile5 {
  --x: 1;
  --y: 1;
}
#tile6 {
  --x: 2;
  --y: 1;
}
#tile7 {
  --x: 0;
  --y: 2;
}
#tile8 {
  --x: 1;
  --y: 2;
}

#tile9 {
  --x: 2;
  --y: 2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursive {
  font-family: 'Sacramento', cursive;
  background: black;
  color: rgb(209, 254, 197);
  text-shadow: 1px 1px 10px rgb(24, 245, 50), 1px 1px 10px rgb(32, 243, 57);
  font-size: 48px;
  text-align: center;
}

.self {
  font-family: 'Sacramento', cursive;
  background: black;
  color: rgb(209, 254, 197);
  text-shadow: 1px 1px 10px rgb(24, 245, 50), 1px 1px 10px rgb(32, 243, 57);
  text-align: center;
}

.boxes {
  opacity: 0.03;
  background-image: linear-gradient(
      #f1f5f9 1px,
      transparent 1px
    ),
    linear-gradient(
      to right,
      #f1f5f9 1px,
      #0f172a 1px
    );
  background-size: 46px 46px;
}

.flip{
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1) rotate(-90deg);
}

.drop-container{
  right: -1.85%;
  bottom: 1%;
}

.toggle-buttons {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  z-index: 1;
  filter: url("#goo");
  text-align: center;
  line-height: 10px;
  color: white;
}

.toggle-btn {
  width: 5px;
  height: 5px;
  background-color: transparent;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 100%;
  filter: blur(0px);
}

.drop {
  width: 15px;
  height: 15px;
  background-color: #ec4899;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-135deg);
  position: absolute;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  border-bottom-left-radius: 100%;
  animation: drop 10s ease-in infinite;
  animation-delay: 10s; 
  filter: blur(0.5px);
}


@keyframes drop {
  90% {
    transform: translate(-50%, 100%) rotate(-135deg);
    border-bottom-right-radius: 0%;
  }
  100% {
    transform: translate(-50%, 27vh) rotate(-135deg);
    border-bottom-right-radius: 100%;
  }
}

.wave-container{
  padding-top: 23vh;
}

.wave {
  position: relative;
  margin: 175px auto;
  opacity: 0;
  top: 0;
    width: 2px;
    height: 1px;
  border: #ec4899 7px solid;
    -moz-border-radius: 300px / 150px;
    -webkit-border-radius: 300px / 150px;
    border-radius: 300px / 150px;
  -moz-animation-name: ripple;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -moz-animation-delay: 15000;
  -webkit-animation-delay: 13s;
  animation-delay: 13s;
  -moz-animation-duration: 10s;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  z-index: 10;
}

.wave-container2{
  padding-top: 23vh;
}

.wave2 {
  position: relative;
  margin: 175px auto;
  opacity: 0;
  top: 0;
  width: 2px;
  height: 1px;
  border: #ec4899 7px solid;
  -moz-border-radius: 300px / 150px;
  -webkit-border-radius: 300px / 150px;
  border-radius: 300px / 150px;
  -moz-animation-name: ripple2;
  -webkit-animation-name: ripple2;
  animation-name: ripple2;
  -moz-animation-delay: 15000;
  -webkit-animation-delay: 13.85s;
  animation-delay: 13.85s;
  -moz-animation-duration: 10s;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.splash-container{
  padding-top: 22vh;
}

.splash{
  position: relative;
  margin: 175px auto;
  opacity: 0;
  top: 0;
  width: 8px;
  height: 8px;
  -moz-animation-name: splash;
  -webkit-animation-name: splash;
  animation-name: splash;
  -moz-animation-delay: 0;
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
  -moz-animation-duration: 10s;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}


@keyframes ripple {
  0%, 69%{
    opacity: 0;
    top: 0;
    width: 2px;
    height: 1px;
    border: #ec4899 7px solid;
    -moz-border-radius: 300px / 150px;
    -webkit-border-radius: 300px / 150px;
    border-radius: 300px / 150px;
  }
    70% {
      opacity: 1;
    }
    100% {
      border: #f1f5f9 5px solid;
      width: 600px;
      height: 300px;
      border-width: 1px;
      top: -100px;
      opacity: 0;
    }
}

@keyframes ripple2 {
  0%, 69%{
    opacity: 0;
    top: 0;
    width: 2px;
    height: 1px;
    border: #ec4899 7px solid;
    -moz-border-radius: 300px / 150px;
    -webkit-border-radius: 300px / 150px;
    border-radius: 300px / 150px;
  }
    70% {
      opacity: 1;
    }
    100% {
      border: #f1f5f9 5px solid;
      width: 400px;
      height: 200px;
      border-width: 1px;
      top: -60px;
      opacity: 0;
    }
}

@keyframes splash {
  0%,91%{
    top:0px;
    opacity:0
  }
  92%{
    top:0px;
    opacity:0
  }
  96% {
      top: -15px;
      opacity:1;
    }
  98%{
    opacity: 1;
  }
  100% {
      top: 18px;
      opacity:0;
    }
}

@media only screen and (max-width: 700px) {
  .toggle-buttons  {
    width: 50%;
    height: 50%;
  }
  .drop{
    width: 10px;
    height: 10px;
    left: 175%;
    top: 140%;
  }
  .wave-container, .wave-container2{
    padding-top: 19vh;
  }
  .splash-container{
    padding-top: 18vh;
  }
}


.shelf{
  width:520px;
  height: 20px;
  background-color: #0f172a;
  position: absolute;
  bottom:10%;
}
.clock{
  width: 110px;
  height: 110px;
  background-color: #e2e8f0;
  border: 10px solid black;
  position: absolute;
  border-radius: 50%;
  left:50px;
  top:-115px
}
.knot{
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
}
.hand{
  width: 4px;
  height: 50px;
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%,-100%);
  transform-origin: bottom;
}
.hour-hand{
  height: 30px;
  background-color: black;
  transform: translate(-50%,-100%) rotate(45deg);
  animation: 216s hour-hand-animation linear infinite;
}
.min-hand{
  height: 40px;
  width: 3px;
  background-color: black;
  transform: translate(-50%,-100%) rotate(160deg);
  animation: 36s min-hand-animation linear infinite;
}
.second-hand{
  height: 43px;
  width: 2px;
  background-color: #e11d48;
  transform: translate(-50%,-100%) rotate(-40deg);
  animation: 6s second-hand-animation linear infinite;
}

.clock-foot-left,.clock-foot-right{
  height: 50px;
  width: 12px;
  background-color: #47321a;
  position: absolute;
  bottom: -25px;
  z-index: -1;
}
.clock-foot-left{
  transform: rotate(30deg);
  left: 20px;
}
.clock-foot-right{
  transform: rotate(-30deg);
  right: 20px;
}
.book-1,.book-2,.book-3{
  width: 40px;
  position: absolute;
}
.book-1{
  height: 170px;
  left: 200px;
  top:-170px;
  background-color: #0d9488;
  animation-delay: 1s;
}
.book-2{
  height: 195px;
  top:-195px;
  left: 240px;
  background-color: #334155;
  animation-delay: 3s;
}
.book-3{
  left: 280px;
  height: 180px;
  top:-180px;
  background-color: #ea580c;
  animation-delay: 2s;
}
.book-1::before,.book-2::before,.book-3::before{
  content: " ";
  position: absolute;
  width: 100%;
  height: 20px;
  background-color: #f8fafc;
  top:20px;
  left:0;
}
.book-1::after,.book-2::after,.book-3::after{
  content: " ";
  width: 50%;
  height: 100%;
  background-color: black;
  opacity: 0.15;
  position: absolute;
  top: 0;
  right: 0;
}

#cactus-container {
  margin: 10% auto;
  width: 125px;
  height: 200px;
  position: absolute;
  left: 360px;
  top:-252px;
}

.cactus-plant {
  background: #26A65B;
  width: 80%;
  height: 47.6%;
  margin: 0 auto;
  border-radius: 50% 50% 0 0;
  position: relative;
  overflow: hidden;
  transform-origin: bottom right;
}

.cactus-needle {
  background: #000;
  width: 10%;
  height: 2px;
  position: relative;
  transform: rotate(54deg);
  float: right;
  left: 3%;
  bottom: -15%;
  margin: 10% 15% 6% 6%;
  transition: all 0.3s ease;
}


.cactus-pot-top {
  background: #d35400;
  width: 100%;
  height: 14.3%;
  margin: 0 auto;
}

.cactus-pot {
    background: #d35400;
    width: 90%;
    height: 38.1%;
    margin: 0 auto;
    border-radius: 0 0px 25px 25px;
    position: relative;
}

.cactus-pot:after {
    background: rgba(230, 126, 34, 0.54);
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    border-radius: 0 0px 0px 25px;
}

#eye {
background: #000;
    width: 10%;
    height: 15%;
    border-radius: 450%;
    z-index: 9999;
    position: relative;
    top: 25%;
    right: 35%;
    margin-left: 10%;
    float: right;
}
#eye:before {
    content: "";
    background: #fff;
    width: 3px;
    height: 3px;
    position: absolute;
    border-radius: 50%;
    top: 20%;
    left: 25%;
    transition: all 0.3s ease;
    border: none;

}
#eye:after {
    content: "";
    background: #fff;
    width: 3px;
    height:3px;
    position: absolute;
    border-radius: 50%;
    top: 45%;
    left: 45%;
    transition: all 0.3s ease;
}

#maoth {
    background: #000;
    height: 10%;
    width: 23%;
    position: absolute;
    z-index: 9999;
    position: relative;
    top: 60%;
    right: -1%;
    float: right;
    border-radius: 0 0 100px 100px;
  transition: all 0.3s ease;
}

#cactus-container:hover #eye:after {
  left: 25%;
}

#cactus-container:hover #eye:before {
  left: 45%;
}

#cactus-container:hover #maoth {
  height: 12px;
}


@keyframes min-hand-animation{
  100%{
    transform: translate(-50%,-100%) rotate(520deg);
  }
}
@keyframes second-hand-animation{
  100%{
    transform: translate(-50%,-100%) rotate(320deg);
  }
}
@keyframes hour-hand-animation{
  100%{
    transform: translate(-50%,-100%) rotate(405deg);
  }
}



.btn {
  display: inline-block;
  width: auto; 
  height: auto;
  background-color: transparent;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-2::before {
  background-color: #fff;
  transition: 0.3s ease-out;
  border-radius: 8px;
}
.btn-2 span {
  color: #f1f5f9;
  transition: 0.2s;
}  
.contact-container:hover .btn-2 span{
  color: #0f172a;
  transition: 0.3s;
}

/* 6. hover-slide-down */

.btn.hover-slide-down::before,
.btn.hover-slide-down::after {
  position: absolute;
  content: "";
  z-index: 1;
}

.btn.hover-slide-down::before {
  top: 0; left: 0; right: 0; 
  height: 0%; width: 100%;
}

.contact-container:hover .btn.hover-slide-down::before {
  height: 100%;
}

.area{
  background: #0f172a;
  width: 100%;
  height:100%;
  position: absolute;
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(241,245,249, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

.circles .icon-squares{
  background: transparent;
}

@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}

/* 11. hover-border-1 */
.btn.hover-border-1::before,
.btn.hover-border-1::after {
  position: absolute;
  content: "";
  width: 15%; 
  height: 35%;
  transition: 0.35s;
}
.btn.hover-border-1::before {
  top: 0; left: 0;
  border-left: 2px solid #0f172a;
  border-top: 2px solid #0f172a;
}
.btn.hover-border-1::after {
  bottom: 0; right: 0;
  border-right: 2px solid #0f172a;
  border-bottom: 2px solid #0f172a;
}
.projects-container:hover .btn.hover-border-1::before,
.projects-container:hover .btn.hover-border-1::after {
  width: 110%;
  height: 120%;
}



/* Animations */
@-webkit-keyframes bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@-moz-keyframes    bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@-o-keyframes      bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@keyframes         bg-scrolling-reverse {
  100% { background-position: 50px 50px; }
}
@-webkit-keyframes bg-scrolling {
  0% { background-position: 50px 50px; }
}
@-moz-keyframes    bg-scrolling {
  0% { background-position: 50px 50px; }
}
@-o-keyframes      bg-scrolling {
  0% { background-position: 50px 50px; }
}
@keyframes         bg-scrolling {
  0% { background-position: 50px 50px; }
}

.projects-container {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC") repeat 0 0;
  -webkit-animation: bg-scrolling-reverse 3s infinite; /* Safari 4+ */
  -moz-animation:    bg-scrolling-reverse 3s infinite; /* Fx 5+ */
  -o-animation:      bg-scrolling-reverse 3s infinite; /* Opera 12+ */
  animation:         bg-scrolling-reverse 3s infinite; /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function:    linear;
  -o-animation-timing-function:      linear;
  animation-timing-function:         linear;
}

.ani-underline::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background-color: #1ca89b; /* Change color as needed */
  transition: width 0.3s ease;
}

.ani-underline:hover::after {
  width: 100%;
}

.figma-iframe-container .chromeSpacer {
  display: none !important;
}

.embed_documentation_footer--documentationFooter--cRlI0{
  display: none !important;
}